import { TimePeriod } from './types';

const TIME_PERIODS: TimePeriod[] = [
  {
    name: 'Inception To Date',
    abbreviation: 'ITD',
  },
  {
    name: 'Year To Date',
    abbreviation: 'YTD',
  },
  {
    name: 'Quarter To Date',
    abbreviation: 'QTD',
  },
  {
    name: 'Month To Date',
    abbreviation: 'MTD',
  }, {
    name: 'Last Twelve Months',
    abbreviation: 'LTM',
  },
  {
    name: 'Calendar Year',
    abbreviation: 'CY',
  },
  {
    name: 'At Investment Entry',
    abbreviation: 'At Entry',
  }, {
    name: 'At Investment Exit',
    abbreviation: 'At Exit',
  },
];

export default { TIME_PERIODS };
