import { createRouter, createWebHistory } from 'vue-router';
import { logger } from '@/store/logger';
import EntityOverview from '@/components/entities/entity/EntityOverview.vue';
import EntityMetrics from '@/components/entities/entity/EntityMetrics.vue';
import Profile from '@/views/Profile.vue';
import FEATURE_FLAGS from '@/store/helpers/featureFlags';
import store from '../store'; // eslint-disable-line import/no-cycle
import SignIn from '../components/auth/SignIn.vue';
import ForcePasswordChange from '../components/auth/ForcePasswordChange.vue';
import RequestResetPassword from '../components/auth/RequestResetPassword.vue';
import ResetPassword from '../components/auth/ResetPassword.vue';
import Users from '../views/Users.vue';

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    redirect: '/upload',
  },
  {
    path: '/login',
    name: 'Login', // Note, the 'SignIn' named component should be used when redirecting to login
    component: () => import('../views/Login.vue'),
    meta: {
      isAuthRoute: true,
      shouldHideNav: true,
    },
    children: [
      {
        path: 'forcePasswordChange',
        name: 'ForcePasswordChange',
        component: ForcePasswordChange,
        props: true,
      },
      {
        path: 'requestResetPassword',
        name: 'RequestResetPassword',
        component: RequestResetPassword,
        props: true,
      },
      {
        path: 'resetPassword',
        name: 'ResetPassword',
        component: ResetPassword,
        props: true,
      },
      {
        path: '',
        alias: '/',
        name: 'SignIn',
        component: SignIn,
      },
    ],
  },
  {
    path: '/login-idp',
    name: 'LoginFromIdentityProvider',
    component: () => import('../views/LoginFromIdentityProvider.vue'),
    meta: {
      isAuthRoute: true,
      shouldHideNav: true,
    },
  },
  {
    path: '/verification',
    name: 'Verification',
    // route level code-splitting
    // this generates a separate chunk (verification.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "verification" */ '../views/DocumentsViewWrapper.vue'),
    props: () => ({ stage: 'EXT_DONE' }),
  },
  {
    path: '/archive',
    name: 'Archive',
    component: () => import(/* webpackChunkName: "verification" */ '../views/DocumentsViewWrapper.vue'),
    props: () => ({ stage: 'ARCHIVED' }),
  },
  {
    path: '/trash',
    name: 'Trash',
    component: () => import(/* webpackChunkName: "verification" */ '../views/DocumentsViewWrapper.vue'),
    props: () => ({ stage: 'TRASHED' }),
  },
  {
    path: '/qa',
    name: 'Manual QA',
    component: () => import(/* webpackChunkName: "verification" */ '../views/DocumentsViewWrapper.vue'),
    props: () => ({ stage: 'EXT_MANUAL_QA' }),
  },
  {
    path: '/verification/:documentRequestId/document',
    name: 'Document',
    component: () => import('@/components/pdfReader/PspdfkitWrapperViewer.vue'),
    props: (route) => {
      const { documentRequestId } = route.params;
      return { documentRequestId };
    },
    meta: {
      shouldHideNav: true,
      hasTopNav: false,
    },
  },
  {
    path: '/verification/:documentRequestId/:breadcrumbIndex',
    name: 'VerifyTabledDocument',
    component: () => import('../views/VerifyTabledDocument.vue'),
    props: (route) => {
      const { documentRequestId, breadcrumbIndex } = route.params;
      const numBcIdx = Number.parseInt(breadcrumbIndex, 10);
      const bcIdx = Number.isFinite(numBcIdx) ? numBcIdx : 0;
      return { documentRequestId, breadcrumbIndex: bcIdx };
    },
    meta: {
      shouldHideNav: true,
      hasTopNav: false,
    },
  },
  {
    path: '/schemas',
    name: 'Schemas',
    component: () => import('../views/Schemas.vue'),
    meta: {
      hasTopNav: false,
    },
  },
  {
    path: '/schemas/:schemaId',
    name: 'SchemaBuilder',
    component: () => import('../views/SchemaBuilder.vue'),
    meta: {
      hasTopNav: false,
    },
    props: (route) => ({ schemaId: route.params.schemaId }),
  },
  {
    path: '/schemas/new',
    name: 'SchemaBuilder-New',
    component: () => import('../views/SchemaBuilder.vue'),
    meta: {
      hasTopNav: false,
    },
    props: { isNew: true },
  },
  {
    path: '/schemas/new-draft-copy/:templateSchemaId',
    name: 'SchemaBuilder-Copy',
    component: () => import('../views/SchemaBuilder.vue'),
    meta: {
      hasTopNav: false,
    },
    props: (route) => {
      const { templateSchemaId } = route.params;
      return { isNew: true, isCopy: true, templateSchemaId };
    },
  },
  {
    path: '/usage',
    name: 'Usage',
    component: () => import('../views/Usage.vue'),
    props: true,
  },
  {
    path: '/entities',
    name: 'entities',
    component: () => import('../views/EntitiesViewWrapper.vue'),
    props: true,
  },
  {
    path: '/entities/:name',
    name: 'entity',
    component: () => import('../views/EntityViewWrapper.vue'),
    redirect: { name: 'entity-overview' },
    props: true,
    children: [
      {
        path: '',
        component: EntityOverview,
        name: 'entity-overview',
      },
      {
        path: 'relationships',
        component: () => import(/* webpackChunkName: "verification" */ '../views/EntityRelationshipsViewWrapper.vue'),
        name: 'entity-relationships',
      },
      {
        path: 'metrics',
        component: EntityMetrics,
        name: 'entity-metrics',
      },
    ],
  },
  {
    path: '/metrics',
    name: 'metrics',
    component: () => import('../views/Metrics.vue'),
    props: true,
    beforeEnter: (to, from, next) => {
      const enableView = FEATURE_FLAGS.ENABLE_METRIC_MANAGEMENT;
      if (enableView) {
        next();
      } else {
        next(false);
      }
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/KgDashboard.vue'),
    props: true,
  },
  {
    path: '/upload',
    name: 'Upload',
    component: () => import('../views/Upload.vue'),
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('../views/Settings.vue'),
    props: true,
    children: [
      {
        path: 'users',
        component: Users,
        name: 'users',
      },
      {
        path: 'profile',
        component: Profile,
        name: 'profile',
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const isAuthenticated = async () => store.getters['authenticate/isAuthenticated'];

const isAuthenticatedFromStorage = async () => {
  await store.dispatch('authenticate/attemptAuthFromStorage');

  return isAuthenticated();
};

router.beforeEach((to, from, next) => {
  logger.debug('before route:', from, to);
  const toIsAuthRoute = to.matched.some((record) => record.meta && record.meta.isAuthRoute);
  logger.debug('before route, from:', from, 'to:', to, 'toIsAuthRoute:', toIsAuthRoute);
  // We don't want to attempt auth from storage when force changing passwords etc.
  const isAuthedPromise = toIsAuthRoute ? isAuthenticated : isAuthenticatedFromStorage;

  isAuthedPromise().then((isAuthed) => {
    if (!toIsAuthRoute && !isAuthed) {
      logger.debug('Setting desired entry url to:', to.path);
      store.commit('authenticate/SET_DESIRED_ENTRY_URL', to.path);
      next({ name: 'SignIn' });
    } else {
      logger.debug('Auth restored successfully, continue to:', to.path);
      next();
    }
  });
});

export default router;
