<template>
  <div
    class="multi-tag-input"
    :class="{'multi-tag-input-v2': v2}"
  >
    <ActionButtonRefresh
      v-if="v2 && !readOnlyMode"
      :icon-only="true"
      variant="TERTIARY"
      :style="{'border-radius': '100%', 'aspect-ratio': 1}"
      size="xs"
      @on-click="addInputTag"
    >
      <template #icon>
        <plus-icon />
      </template>
    </ActionButtonRefresh>
    <div
      v-for="(tag, idx) in modelValue"
      :key="idx"
      :class="{ 'input-tag': true, 'input-tag-disabled': isReadOnlyValue(tag) }"
    >
      <div :class="{ 'input-tag-content': !isReadOnlyValue(tag) }">
        <input
          :class="`${id}-tag-input`"
          :value="tag"
          v-bind="{
            ...v2 && {size: (tag?.length > 0 ? tag.length : 1)},
          }"
          :disabled="isReadOnlyValue(tag)"
          @blur="onInputBlur"
          @input="updateTagText(idx, $event.target.value)"
        >
      </div>
      <div
        v-if="!isReadOnlyValue(tag)"
        class="input-tag-delete"
        @click.stop="deleteInputTag(idx)"
      >
        <x-icon />
      </div>
    </div>
    <div
      v-if="!readOnlyMode && !v2"
      class="input-tag input-tag-add"
      @click.stop="addInputTag"
    >
      <div class="add-icon">
        <plus-icon />
      </div>
    </div>
  </div>
</template>
<script>
import { useToast } from 'vue-toastification';
import { PlusIcon, XIcon } from '@zhuowenli/vue-feather-icons';
import { errorMessages } from '@/store/helpers/display/toastMessages';
import { v4 as uuid } from 'uuid';
import ActionButtonRefresh from '../general/buttons/ActionButtonRefresh.vue';

export default {
  components: {
    PlusIcon,
    XIcon,
    ActionButtonRefresh,
  },
  props: {
    modelValue: {
      type: Array,
      required: true,
    },
    readOnlyValues: {
      type: Array,
      default: () => [],
    },
    readOnlyMode: {
      type: Boolean,
      default: false,
    },
    v2: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'inputBlur'],
  data() {
    return {
      toast: useToast(),
      id: uuid(),
    };
  },
  methods: {
    addInputTag() {
      if (this.v2) {
        this.$emit('update:modelValue', ['', ...this.modelValue]);
        setTimeout(() => {
          const element = document.getElementsByClassName(`${this.id}-tag-input`)[0];
          element.focus();
        }, 0);
      } else {
        const arrCopy = Array.from(this.modelValue);
        arrCopy.push('');
        this.$emit('update:modelValue', arrCopy);
      }
    },
    deleteInputTag(idx) {
      const arrCopy = Array.from(this.modelValue);
      arrCopy.splice(idx, 1);
      this.$log.info('deleting idx', idx, 'MultiTagInput:', arrCopy);
      this.$emit('update:modelValue', arrCopy);
      this.onInputBlur();
    },
    updateTagText(idx, value) {
      // This is to handle an edge case when the user tries to add/update
      // an aka with the same value as a read only aka. In this case, we
      // remove the aka and toast an error.
      if (this.isReadOnlyValue(value)) {
        this.deleteInputTag(idx);
        this.toast.error(errorMessages.CANNOT_DUPLICATE_AKA);
        return;
      }

      const arrCopy = Array.from(this.modelValue);
      arrCopy[idx] = value;
      this.$emit('update:modelValue', arrCopy);
    },
    onInputBlur() {
      this.$emit('inputBlur');
    },
    isReadOnlyValue(value) {
      return this.readOnlyMode || this.readOnlyValues.includes(value);
    },
  },
};
</script>

<style scoped lang="scss">
/** V2 UI/UX Refresh */
.multi-tag-input-v2 {
  display: flex;
  align-items: center;
  align-content: center;
  gap: 4px;
  min-height: 44px;

  .input-tag {
    display: flex;
    padding: 4px 12px;
    align-items: center;
    gap: 8px;

    border-radius: 1000px;
    background: #EFF5F6;
  }

  input {
    max-width: 200px;
    color: #1A2C2D;

    /* Body/Body 2 */
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }

  .input-tag-content {
    padding: unset;
    border-right: unset !important;
  }

  .input-tag-delete {
    color: #426D70;
    &:hover {
      background-color: unset !important;
    }
  }

  .input-tag-add {
    width: 24px;
    height: 24px;
    border: 1.8px solid #426D70;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;

    .add-icon {
      color: #426D70;
      padding: 0;
    }

    &:hover {
      background-color: #EFF5F6;
    }

    &:hover {
      box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.60), 0px 0px 0px 1px #426D70;
    }

    &:active {
      background: #D9E2E2;
      box-shadow: unset;
    }
  }
}
</style>
