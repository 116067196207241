import { logger } from '@/store/logger';
import { ActionContext } from 'vuex';
import {
  storeState, storeStateGetters, storeStateMutations, allowedStates,
} from '../helpers/storeState';
import Api from '../helpers/api';
import { State } from './types';

const store = {
  namespaced: true,
  state: {
    ...storeState,
    entityTypes: null,
  },

  getters: {
    ...storeStateGetters,
    entityTypes: (state: State) => state.entityTypes,
  },

  mutations: {
    ...storeStateMutations,
  },

  actions: {
    lazyInit: async ({ commit, dispatch, getters }: ActionContext<State, any>) => {
      commit('SET_STORE_STATUS', allowedStates.IS_LOADING);

      if (getters.entityTypes !== null) {
        commit('SET_STORE_STATUS', allowedStates.IS_READY);
        return Promise.resolve(getters.entityTypes);
      }

      try {
        const response = await dispatch('getEntityTypes');
        commit('SET_PROP', { key: 'entityTypes', value: response.metrics });
        commit('SET_STORE_STATUS', allowedStates.IS_READY);
        return response;
      } catch (e) {
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        throw e;
      }
    },

    getEntityTypes: ({ rootGetters }: ActionContext<State, any>) => {
      logger.debug(`Getting entity types, offline: ${rootGetters['entityRequest/offline']}`);

      return (new Api(process.env, rootGetters['authenticate/idToken']))
        .get('schemas/metric-identifiers?metric_identifier_type=ENTITY_NAME');
    },
  },
};

export default store;
