import { logger } from '@/store/logger';
import isEqual from 'lodash.isequal';
import {
  ComputedRef,
  Ref, computed, ref, watch,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ColumnFilter } from './interfaces/useTableInterfaces';
import { RowSelection } from './interfaces/useTable';

/**
 * A Row is just a record of key value pairs.
 * One of the keys must be a unique identifier
 */
type Row = Record<string, any>;

type Props = {
  initialiseStore: (
    orderBy?: string | null,
    searchBy?: string | null,
    columnFilters?: ColumnFilter[],
    page?: number,
    maxPageSize?: number,
  ) => Promise<any>;
  columnNames: string[];
  sortRows: (orderBy: string | null) => Promise<any>;
  filterRowsBySearch: (searchBy: string | null) => Promise<any>;
  applyColumnFilters?: (filters: ColumnFilter[]) => Promise<any>;
  enableQueryControlledPagination?: boolean;
  updateCurrentPageAndMaxPageSize?: (page: number, maxPageSize: number) => Promise<any>;
  rows: ComputedRef<Row[]>;
  rowKey: string;
  totalItemCount?: ComputedRef<number>;
};

export default (
  {
    initialiseStore,
    columnNames,
    sortRows,
    filterRowsBySearch,
    applyColumnFilters = (): any => { },
    enableQueryControlledPagination = false,
    updateCurrentPageAndMaxPageSize = async () => { },
    rows,
    rowKey,
    totalItemCount = computed(() => 0),
  }: Props,
) => {
  const route = useRoute();
  const router = useRouter();

  /* Table sorting */
  const isValidOrderByQuery = (query: any) => {
    const regex = new RegExp(`^(${columnNames.join('|')}):(asc|desc)$`);
    return regex.test(query);
  };

  const orderByQueryParam = computed(() => {
    const val = route.query.orderBy;
    if (typeof val === 'string' && isValidOrderByQuery(val)) {
      return val;
    }
    return null;
  });

  const columnFiltersQueryParams: ComputedRef<ColumnFilter[]> = computed(() => {
    const columnNamesInQuery = Object.keys(route.query).filter((key) => columnNames.includes(key));
    return columnNamesInQuery.map((columnName) => {
      const values = Array.isArray(route.query[columnName])
        ? route.query[columnName] : [route.query[columnName]];
      return {
        columnName,
        values: values as string[],
      };
    });
  });

  watch((orderByQueryParam), (newVal, oldVal) => {
    logger.debug(`Detected change in orderBy query param: ${oldVal} -> ${newVal}`);
    sortRows(newVal);
  });

  watch((columnFiltersQueryParams), (newFilters, oldFilters) => {
    if (isEqual(newFilters, oldFilters)) {
      return;
    }
    logger.debug('Detected change in column filters query params', newFilters, oldFilters);
    applyColumnFilters(newFilters);
  });

  /** Table pagination */
  const isValidPageAndMaxPageSizeQueryParams = (page: number, maxPageSize: number) => {
    const totalPages = Math.ceil(totalItemCount.value / maxPageSize);
    return (page <= totalPages)
      && ([20, 50, 100].includes(maxPageSize));
  };

  const pageAndMaxPageSizeQueryParams = computed(() => {
    if (!enableQueryControlledPagination) { return ({ page: 0, maxPageSize: 0 }); }
    const pageParam = parseInt(route.query.page?.toString() ?? '', 10);
    const maxPageSizeParam = parseInt(route.query.maxPageSize?.toString() ?? '', 10);
    return { page: pageParam, maxPageSize: maxPageSizeParam };
  });

  watch(pageAndMaxPageSizeQueryParams, (newVal, oldVal) => {
    if (isEqual(newVal, oldVal) || !enableQueryControlledPagination) { return; }
    const { page, maxPageSize } = newVal;
    logger.debug('Detected change in pageAndMaxPageSizeQueryParams', oldVal, newVal);
    if (
      !Number.isNaN(page) && !Number.isNaN(maxPageSize)
      && isValidPageAndMaxPageSizeQueryParams(page, maxPageSize)
    ) {
      updateCurrentPageAndMaxPageSize(page, maxPageSize);
    } else {
      router.push({ query: { ...route.query, page: '1', maxPageSize: '20' } });
    }
  });

  /* Table filtering (by search) */
  const searchByQueryParam = computed(() => {
    const val = route.query.searchBy;
    if (val) {
      return val.toString();
    }
    return null;
  });

  watch((searchByQueryParam), (newVal, oldVal) => {
    logger.debug(`Detected change in searchBy query param: ${oldVal} -> ${newVal}`);
    filterRowsBySearch(newVal);
  });

  /* Row selection */
  const rowSelection: Ref<RowSelection> = ref({});
  const rowIds: ComputedRef<string[]> = computed(() => rows.value.map((row: Row) => row[rowKey]));

  const selectedRowIds: ComputedRef<string[]> = computed(() => Object.keys(rowSelection.value)
    .filter((key: string) => rowSelection.value[key].isSelected === true));

  const selectedRows: ComputedRef<any[]> = computed(
    () => selectedRowIds.value.map((id) => rowSelection.value[id].value),
  );

  const isAllRowsSelected = computed(() => {
    const ids = rowIds?.value ?? [];
    return ids.every((val) => selectedRowIds.value.includes(val));
  });

  function toggleSelectRow(id: string) {
    const value = rows.value.find((row: Row) => row[rowKey] === id);
    const isCurrentlySelected = rowSelection.value[id]?.isSelected;
    rowSelection.value[id] = {
      isSelected: !isCurrentlySelected,
      value,
    };
  }

  function toggleSelectMultipleRows(ids: string[]) {
    const tempRowSelection: RowSelection = {};
    ids.forEach((id) => {
      const value = rows.value.find((row: Row) => row[rowKey] === id);
      const isCurrentlySelected = rowSelection.value[id]?.isSelected;
      tempRowSelection[id] = {
        isSelected: !isCurrentlySelected,
        value,
      };
    });
    rowSelection.value = { ...rowSelection.value, ...tempRowSelection };
  }

  function selectAll() {
    rows?.value.forEach((row: Row) => {
      const id = row[rowKey];
      rowSelection.value[id] = {
        isSelected: true,
        value: row,
      };
    });
  }

  function unselectAll() {
    rows?.value.forEach((row: Row) => {
      const id = row[rowKey];
      rowSelection.value[id] = {
        isSelected: false,
        value: row,
      };
    });
  }

  function resetRowSelection() {
    rowSelection.value = {};
  }

  /** Store */
  const init = async () => {
    const { page, maxPageSize } = pageAndMaxPageSizeQueryParams.value;
    const paginationParams = enableQueryControlledPagination ? [page, maxPageSize] : [];
    initialiseStore(
      orderByQueryParam.value,
      searchByQueryParam.value,
      columnFiltersQueryParams.value,
      ...paginationParams,
    );
  };

  return {
    init,
    rowSelection,
    selectedRowIds,
    selectedRows,
    toggleSelectRow,
    toggleSelectMultipleRows,
    selectAll,
    unselectAll,
    resetRowSelection,
    isAllRowsSelected,
  };
};
